<template>
  <div class="container mx-auto p-4 md:px-8 my-8 pt-36 pb-32">
    <h1 class="text-4xl font-bold mb-8">Edit Product</h1>
    <div v-if="products.length === 0" class="text-gray-600 text-lg">
      No product available. Please add product.
    </div>
    <div v-else>
      <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <li v-for="product in products" :key="product.product_id" class="bg-white rounded-lg shadow-md overflow-hidden">
          <div class="p-4">
            <h2 class="text-xl font-semibold mb-2">{{ product.product_name }}</h2>
            <p class="text-gray-600 truncate">{{ product.text }}</p>
          </div>
          <div class="flex items-center justify-between p-4 bg-gray-100">
            <button @click="toggleEditForm(product)" class="text-blue-600 hover:underline">Edit</button>
            <button @click="deleteProduct(product.product_id)" class="text-red-600 hover:underline">Delete</button>
          </div>
        </li>
      </ul>
    </div>

    <!-- Show the edit form only when isEditFormVisible is true -->
    <div v-if="isEditFormVisible" class="popup">
      <div class="popup-content">
        <h2 class="text-2xl font-semibold mb-4">Edit Product</h2>
        <form @submit.prevent="saveEditedProduct" class="space-y-4">
          <div class="mb-4">
            <label for="productName" class="block text-sm font-medium text-gray-600">Product Name:</label>
            <input type="text" v-model="editedProduct.name" id="productName" name="productName" class="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500">
          </div>
          <div class="mb-4">
            <label for="textProduct" class="block text-sm font-medium text-gray-600">Description:</label>
            <textarea v-model="editedProduct.text" id="textProduct" name="textProduct" rows="6" class="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"></textarea>
          </div>
          <div class="mb-4">
            <button type="submit" class="bg-primary text-white py-2 px-4 hover:text-primary rounded hover:bg-secondary">Save Changes</button>
            <button @click="cancelEdit" type="button" class="bg-gray-300 text-black py-2 px-4 rounded ml-2">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      products: [],
      editingProduct: null,
      editedProduct: {
        name: "",
        text: "",
      },
      isEditFormVisible: false,
    };
  },
  methods: {
    async getProduct() {
      try {
        const response = await axios.get('https://flask.montcartenz.com/GetProduct');
        this.products = response.data;
      } catch (e) {
        console.error("Error Fetching Product:", e);
      }
    },
    async deleteProduct(productId) {
      try {
        await axios.delete(`https://flask.montcartenz.com/DeleteProduct/${productId}`);
        this.products = this.products.filter((product) => product.product_id !== productId);
      } catch (e) {
        console.error("Error Deleting Product:", e);
      }
    },
    toggleEditForm(product) {
      this.editingProduct = product;
      this.editedProduct = {
        name: product.product_name,
        text: product.text,
      };
      this.isEditFormVisible = true;
    },
    async saveEditedProduct() {
      try {
        console.log('Saving edited product:', this.editedProduct);
        const response = await axios.put(`https://flask.montcartenz.com/EditProduct/${this.editingProduct.product_id}`, this.editedProduct);
        console.log('Server Response:', response);

        // Update the product list with the edited product
        const index = this.products.findIndex(product => product.product_id === this.editingProduct.product_id);
        if (index !== -1) {
          this.products[index].product_name = this.editedProduct.name;
          this.products[index].text = this.editedProduct.text;
        }

        this.editingProduct = null; // Close the edit form after saving changes
        this.isEditFormVisible = false; // Hide the edit form
      } catch (e) {
        console.error("Failed to Save Changes:", e);
      }
    },
    cancelEdit() {
      this.isEditFormVisible = false;
      this.editingProduct = null;
    }
  },
  mounted() {
    document.title = 'Edit Product';
    this.getProduct();
  },
};
</script>

<style scoped>
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
}

.popup-content button {
  margin-top: 10px;
}
</style>
