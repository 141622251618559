<script>
  import axios from "axios";
  export default {
    data(){
      return{
        products: [],
        loading: true,
        selectedProduct: null,
        isPopupOpen: false,
      };
    },
    methods: {
      openPopup(product)
      {
        document.getElementById("product-container").classList.add('blur-filter');
        this.selectedProduct = product;
        this.isPopupOpen = true;
      },
      closePopup(){
        this.selectedProduct = null;
        this.isPopupOpen = false;
        document.getElementById("product-container").classList.remove('blur-filter');
      },
    },
    mounted() {
      document.title = 'Catalogue Product';
      axios.get('https://flask.montcartenz.com/GetProduct')
        .then(response => {
          console.log(response)
          let product = [];
          response.data.forEach(function (obj){
            obj.photo = "https://flask.montcartenz.com/static/image/" + obj.photo;
            product.push(obj)
          });
          this.products = product;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
  };
</script>

<template>
  <div class="container mx-auto p-4 my-36" id="product-container">
    <h1 class="text-2xl font-semibold mb-4 text-center text-shadow-default">Our Product</h1>
    <div v-if="loading">
      <div class="flex items-center justify-center min-h-screen">
        <div class="animate-spin rounded-full h-16 w-16 border-t-2 border-primary border-r-2 border-b-2 border-white"></div>
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <div v-for="product in products" :key="product.product_id" class="mb-4">
          <div class="bg-white shadow-lg rounded-lg">
            <div class="relative overflow-hidden rounded-t-lg" style="padding-top: 100%;">
              <img :src="product.photo" alt="Product" class="absolute inset-0 w-full h-full object-cover transform scale-100 hover:scale-110 transition-transform" loading="lazy">
            </div>
            <div class="p-4">
              <h2 class="text-lg font-semibold text-center">{{product.product_name}}</h2>
              <p class="text-gray-500 truncate pb-3">{{product.text}}</p>
              <div class="text-center">
                <router-link :to="{name: 'product-detail', params: {id: product.product_id}}" class="bg-primary text-white py-2 px-4 mt-2 hover:text-primary rounded hover:bg-secondary">
                  More Info
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.blur-filter {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>