import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ProductUpload from "@/views/ProductUpload.vue";
import AboutView from "@/views/AboutView.vue";
import productCatalog from "@/views/ProductView.vue";
import DetailProduct from "@/views/DetailProduct.vue";
import UploadRecipes from "@/views/UploadRecipes.vue";
import RecipesView from "@/views/RecipesView.vue";
import RecipeDetail from "@/views/RecipeDetail.vue";
import EditRecipe from "@/views/EditRecipe.vue";
import EditProduct from "@/views/EditProduct.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/UploadProduct',
    name: 'Product Upload',
    component: ProductUpload
  },
  {
    path: '/About',
    name: 'About Us',
    component: AboutView
  },
  {
    path: '/Product',
    name: 'Product Catalogue',
    component: productCatalog
  },
  {
    path: '/product/:id',
    component: DetailProduct,
    name: 'product-detail'
  },
  {
    path: '/UploadRecipes',
    component: UploadRecipes,
    name: 'Upload Recipes'
  },
  {
    path: "/Recipes",
    component: RecipesView,
    name: "Recipes List"
  },
  {
    path: "/Recipes/:id",
    component: RecipeDetail,
    name: "RecipeDetails"
  },
  {
    path: "/EditRecipe",
    component: EditRecipe,
    name: "Edit Recipe"
  },
  {
    path: "/EditProduct",
    component: EditProduct,
    name: 'Edit Product'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
