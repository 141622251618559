<script>
export default {
  props: {
    isOpen: Boolean,
    selectedRecipe: Object,
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
    },
  },
};
</script>

<template>
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div class="bg-white w-full sm:w-2/3 md:w-2/3 lg:w-1/2 xl:w-1/3 p-6 rounded-lg shadow-xl relative max-h-screen overflow-y-auto">
      <button @click="closePopup" class="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <img :src="selectedRecipe.photo" :alt="selectedRecipe.name" class="w-full h-32 sm:h-40 object-cover mb-4 rounded-lg shadow-md">
      <h2 class="text-lg font-semibold mb-3 text-primary">{{ selectedRecipe.name }}</h2>
      <div class="mb-3">
        <h3 class="text-md font-semibold mb-2 text-primary">Ingredients:</h3>
        <ul class="list-disc pl-5 space-y-1">
          <li v-for="(ingredient, index) in selectedRecipe.bahan" :key="index" class="text-gray-600">
            {{ ingredient }}
          </li>
        </ul>
      </div>
      <div>
        <h3 class="text-md font-semibold mb-2 text-primary">Instructions:</h3>
        <ol class="list-decimal pl-5 space-y-1">
          <li v-for="(step, index) in selectedRecipe.cara" :key="index" class="text-gray-600">
            {{ step }}
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-primary {
  color: #065f46; /* Contoh warna utama, sesuaikan dengan tema Anda */
}

.shadow-xl {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2), 0 8px 10px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 8px;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
