<script>
  import axios from "axios";
  export default {
    data(){
      return{
        products:[
          {category: "", name: "", text: "", photo: null}
        ]
      };
    },
    methods:{
      addProduct(){
        this.products.push({category: "", name: "", text: "", photo: null});
      },
      removeProduct(index){
        this.products.splice(index, 1);
      },
      onFileChange(event, index){
        this.products[index].photo = event.target.files[0];
      },
      submitForm(){
        const formData = new FormData();

        this.products.forEach(product => {
          formData.append("category[]", product.category);
          formData.append("name[]", product.name);
          formData.append("text[]", product.text);
          formData.append("photo[]", product.photo);
        });
        axios.post('https://flask.montcartenz.com/upload', formData)
            .then(response => {
              console.log(response.data);
              alert("Product Has Been Added");
              window.location.reload();
            })
            .catch(error => {
              console.error(error);
            })
      }
    },
  }
</script>

<template>
  <div class="p-4">
    <h1 class="text-2xl font-semibold mb-4">Upload Product</h1>
    <form @submit.prevent="submitForm" enctype="multipart/form-data">
      <div v-for="(product, index) in products" :key="index" class="border p-4 rounded bg-white mb-4">
        <div class="mb-4">
          <label for="category" class="block text-sm font-medium text-gray-700">Category</label>
          <input v-model="product.category" type="text" id="category" name="category[]" required
                 class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary">
        </div>
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
          <input v-model="product.name" type="text" id="name" name="name[]" required
                 class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary">
        </div>
        <div class="mb-4">
          <label for="text" class="block text-sm font-medium text-gray-700">Text</label>
          <textarea v-model="product.text" id="text" name="text[]" required
                    class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary"></textarea>
        </div>
        <div class="mb-4">
          <label for="photo" class="block text-sm font-medium text-gray-700">Photo</label>
          <input @change="onFileChange($event, index)" type="file" id="photo" name="photo[]" accept="image/*" required
                 class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary">
        </div>
        <button type="button" @click="removeProduct(index)" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Remove</button>
      </div>
      <div class="mb-4">
        <button type="button" @click="addProduct"
                class="px-4 py-2 bg-compliment text-white rounded hover:bg-secondary">Add Product</button>
      </div>
      <button type="submit" class="px-4 py-2 bg-primary text-white rounded hover:bg-yellow-200">Upload Data</button>
    </form>
  </div>
</template>

<style scoped>

</style>