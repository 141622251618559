<script>
import axios from "axios";

export default {
  name: "RecipeDetail",
  data(){
    return{
      checkedIngredients: [],
      checkedInstructions: [],
      recipe: {},
    };
  },
  mounted() {
    const recipeId = this.$route.params.id;
    this.getRecipeById(recipeId);
    document.title = 'Recipe Detail';
  },
  methods:{
    getRecipeById(Id){
      axios
          .get(`https://flask.montcartenz.com/GetRecipesById/${Id}`)
          .then(response => {
            console.log(response);
            this.recipe = response.data.recipe;
            this.recipe.photo = "https://flask.montcartenz.com/static/image/" + response.data.recipe.photo;
            this.recipe.cara = this.pecahText(response.data.recipe.cara);
            this.recipe.bahan = this.pecahText(response.data.recipe.bahan);
            console.log(this.recipe);

            this.checkedIngredients = new Array(this.recipe.bahan.length).fill(false);
            this.checkedInstructions = new Array(this.recipe.cara.length).fill(false);

          })
          .catch(error => {
            console.error(error);
          })
    },
    pecahText(text){
      return text.split(/\.\r\n|\n/).filter(item => item.trim() !== '');
    },
  }
}
</script>

<template>
  <div class="container mx-auto p-4 my-20">
    <router-link to="/Recipes" class="text-blue-500 mb-4 block">&lt; Back to Recipe List</router-link>
    <h1 class="text-4xl font-bold mb-8 text-center">{{ recipe.name }}</h1>
    <div class="flex flex-col lg:flex-row">
      <div class="lg:w-1/2 lg:pr-8 mb-4 lg:mb-0">
        <img :src="recipe.photo" :alt="recipe.name" class="rounded-md w-full h-auto lg:w-96 lg:h-96 mx-auto">
      </div>
      <div class="lg:w-1/2">
        <div class="mb-4">
          <h2 class="text-lg font-semibold mb-2">Bahan-bahan:</h2>
          <ul>
            <li v-for="(ingredient, index) in recipe.bahan" :key="index" class="flex items-center mb-2">
              <input type="checkbox" v-model="checkedIngredients[index]" class="mr-2 text-blue-500">
              <span class="text-gray-700">{{ ingredient }}</span>
            </li>
          </ul>
        </div>
        <div class="mb-4">
          <h2 class="text-lg font-semibold mb-2">Langkah-langkah:</h2>
          <ul>
            <li v-for="(step, index) in recipe.cara" :key="index" class="flex items-center mb-2">
              <input type="checkbox" v-model="checkedInstructions[index]" class="mr-2 text-blue-500">
              <span class="text-gray-700">{{ step }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>