<script>

import axios from "axios";
import RecipeComponent from "@/components/RecipeComponent.vue";

export default{
  components: {RecipeComponent},
  name: 'RecipeList',
  data(){
    return{
      recipes:[],
      loading: true,
    };
  },
  mounted() {
    document.title = 'Catalogue Recipes';
    this.getRecipes();
  },
  methods:{
    getRecipes(){
      axios.get('https://flask.montcartenz.com/GetRecipes')
          .then(response => {
            console.log(response);
            let recipe =[];
            response.data.forEach(function (obj){
              obj.photo = "https://flask.montcartenz.com/static/image/" + obj.photo;
              recipe.push(obj);
            });
            this.recipes = recipe;
            this.loading = false;
          })
          .catch(error => {
            console.error(error);
            this.loading = false;
          })
    },
    viewDetails(recipeId){
      this.$router.push({name:"RecipeDetails", params:{id:recipeId}});
    },
  },
}
</script>

<template>
  <div class="container mx-auto p-4 my-36">
    <div class="p-24">
      <h1 class="text-4xl font-bold mb-8 text-center text-primary text-shadow-default">Our Recommendation Recipes</h1>
    </div>
    <div class="py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <RecipeComponent
        v-for="(recipe, index) in recipes"
        :key="index"
        :recipe="recipe"
        @viewDetails="viewDetails"
      />
    </div>
  </div>
</template>

<style>
/*.recipe-header{*/
/*  background-image: url("https://flask.montcartenz.com/static/image/Mocktail.jpeg");*/
/*  background-attachment: fixed;*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  grid-template-columns: ;*/
/*}*/
</style>