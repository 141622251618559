<script>
  import axios from "axios";
  import RecipeCard from "@/components/RecipeCard.vue";
  import RecipePopup from "@/components/RecipePopup.vue";

  export default {
    components: {RecipePopup, RecipeCard},
    data(){
      return{
        productDetail:{
          product_name: "",
          text: "",
          photo: ""
        },
        recipes: [],
        selectedRecipe: {},
        isRecipePopupOpen: false,
      };
    },
    mounted(){
      const productId = this.$route.params.id;
      this.fetchProductDetail(productId);
      document.title = 'Detail ' + this.productDetail.product_name;
    },
    methods:{
      fetchProductDetail(productId){
        axios
            .get(`https://flask.montcartenz.com/GetProductById/${productId}`)
            .then(response => {
              this.productDetail = response.data.product;
              this.productDetail.photo = "https://flask.montcartenz.com/static/image/" + response.data.product.photo;
              this.fetchRecipes(response.data.product.product_name)
            })
            .catch(error => {
              console.error(error);
            });
      },
      fetchRecipes(productName){
        axios
            .get(`https://montcartenz.com/flaskGetRecipesByProduct/${productName}`)
            .then(response => {
              console.log(response.data.recipes)
              let recipe = [];
              let vm = this;
              response.data.recipes.forEach(function (obj){
                obj.photo = "https://montcartenz.com/flaskstatic/image/" + obj.photo;
                obj.bahan = vm.pecahText(obj.bahan);
                obj.cara = vm.pecahText(obj.cara);
                recipe.push(obj)
              });
              console.log(recipe)
              this.recipes = recipe;
            })
            .catch(error => {
              console.error(error);
            })
      },
      openRecipePopup(recipe) {
        this.selectedRecipe = recipe;
        this.isRecipePopupOpen = true;
      },
      closeRecipePopup() {
        this.isRecipePopupOpen = false;
      },
      pecahText(text){
        return text.split(/\.\r\n|\n/).filter(item => item.trim() !== '');
      }
    }
  };
</script>

<template>
  <section class="pt-36 pb-10">
    <div class="container mx-auto p-4 my-10">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/2 px-4">
          <h1 class="text-xl md:text-2xl lg:text-4xl font-semibold text-primary">
            {{ productDetail.product_name }}
          </h1>
          <p class="text-sm md:text-base lg:text-lg font-medium text-secondary mb-6 md:mb-10 leading-relaxed pt-3 text-justify">
            {{ productDetail.text }}
          </p>
        </div>
        <div class="w-full lg:w-1/2 px-4">
          <div class="relative mt-6 lg:mt-0 lg:right-0">
            <img
              :src="productDetail.photo"
              :alt="productDetail.product_name"
              class="w-full lg:max-w-xs xl:max-w-full mx-auto lg:mx-0 rounded-lg shadow-lg"
            />
            <span class="hidden lg:block absolute bottom-10 -z-10 left-1/2 -translate-x-1/2 md:scale-125">
              <svg
                width="500"
                height="500"
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#065f46"
                  d="M34.4,-17.7C39.8,5.6,36.2,25.2,24.1,34.2C12.1,43.2,-8.4,41.5,-27.9,29.4C-47.3,17.4,-65.7,-5,-60.9,-27.3C-56.1,-49.7,-28,-72.1,-6.8,-69.9C14.5,-67.7,28.9,-40.9,34.4,-17.7Z"
                  transform="translate(100 100) scale(1.1)"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <h2 class="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold mt-6 lg:mt-12 mb-4 text-compliment">
        Try Our Recipes
      </h2>
    </div>
    <div class="recipe-cards flex flex-wrap justify-center">
      <recipe-card
        v-for="recipe in recipes"
        :key="recipe.id"
        :recipe="recipe"
        @open-popup="openRecipePopup"
        class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4"
      />
    </div>
    <recipe-popup
      :selectedRecipe="selectedRecipe"
      :is-open="isRecipePopupOpen"
      @close-popup="closeRecipePopup"
    />
  </section>
</template>

<style scoped>
.text-primary {
  color: #065f46; /* Contoh warna utama, sesuaikan dengan tema Anda */
}

.text-compliment {
  color: #e53e3e; /* Contoh warna pelengkap, sesuaikan dengan tema Anda */
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
}

.rounded-lg {
  border-radius: 8px;
}
</style>
