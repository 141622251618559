<script>
  export default {
    props: {
      recipe: Object,
    },
    methods: {
      openPopup() {
        this.$emit('open-popup', this.recipe);
      },
    },
  };
</script>

<template>
  <div @click="openPopup" class="bg-white shadow-lg rounded-lg p-2 cursor-pointer">
    <div class="relative overflow-hidden h-40 w-40 mx-auto">
      <img :src="recipe.photo" alt="Recipe" class="w-full h-full object-cover">
      <h2 class="absolute bottom-0 left-0 right-0 text-sm font-semibold text-white bg-black bg-opacity-60 p-1">{{ recipe.name }}</h2>
    </div>
  </div>
</template>

<style scoped>

</style>