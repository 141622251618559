<script>
export default {
  name: 'AppFooter',
  data(){
    return{
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<template>
  <footer class="bg-secondary text-white py-6">
    <div class="container mx-auto text-center">
      <p>&copy;{{currentYear}} Mont Cartenz. All rights reserved.</p>
    </div>
  </footer>
</template>

<style scoped>

</style>