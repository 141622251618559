<script>
export default {
  name: "RecipeComponent",
  props:{
    recipe:{
      type: Object,
      required: true,
    },
  },
  methods:{
    truncate(text, length){
      return text.length > length ? text.substring(0, length) + '...':text;
    },
    viewDetails(){
      this.$emit("viewDetails", this.recipe.id)
    }
  },
}
</script>

<template>
  <div class="bg-white p-6 rounded-lg shadow-md">
    <div class="flex items-center mb-4">
      <img :src="recipe.photo" alt="Recipe Image" class="w-24 h-24 object-cover mr-4 rounded-md">
      <div>
        <h2 class="text-xl font-semibold">{{ recipe.name }}</h2>
        <p class="text-gray-600">{{ truncate(recipe.bahan, 50) }}</p>
      </div>
    </div>
    <button @click="viewDetails(recipe.id)" class="bg-primary text-white py-2 px-4 mt-2 hover:text-primary rounded hover:bg-secondary text-bold mx-auto">Lihat Detail</button>
  </div>
</template>

<style>

</style>