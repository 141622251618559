<script>
// eslint-disable-next-line vue/no-export-in-script-setup
export default{
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  mounted() {

    const hamburger = document.querySelector('#hamburger');
    const navMenu = document.querySelector('#nav-menu')

    hamburger.addEventListener('click', function () {
      hamburger.classList.toggle('hamburger-active');
      navMenu.classList.toggle('hidden');
    });
    window.onscroll = function (){
      const header = document.querySelector('header');
      const fixNav = header.offsetTop;

      if (window.pageYOffset > fixNav){
        header.classList.add('navbar-fixed')
      } else {
        header.classList.remove('navbar-fixed')
      }
    };
  }
};
</script>

<template>
<header class="bg-transparent absolute top-0 left-0 w-full flex items-center z-10 py-4">
  <div class="container">
    <div class="flex items-center justify-between relative">
      <div class="px-4">
        <a href="" class="font-bold text-lg text-primary block py-6">Mont Cartenz</a>
      </div>
      <div class="flex items-center px-4">
        <button id="hamburger" name="hamburger" type="button" class="block absolute right-4 lg:hidden">
          <span class="hamburger-line transition duration-300 ease-in-out origin-top-left"></span>
          <span class="hamburger-line transition duration-300 ease-in-out"></span>
          <span class="hamburger-line transition duration-300 ease-in-out origin-bottom-left"></span>
        </button>
        <nav id="nav-menu" class="hidden absolute py-5 bg-white shadow-lg rounded-lg max-w-max w-full right-4 top-full lg:block lg:static lg:bg-transparent lg:max-w-full lg:shadow-none lg:rounded-none">
          <ul class="block lg:flex">
            <li class="group">
              <router-link to="/" class="flex text-base text-secondary py-2 mx-8 group-hover:text-primary">Home</router-link>
            </li>
            <li class="group">
              <router-link to="/Product" class="flex text-base text-secondary py-2 mx-8 group-hover:text-primary">Product</router-link>
            </li>
            <li class="group">
              <router-link to="/About" class="flex text-base text-secondary py-2 mx-8 group-hover:text-primary">About</router-link>
            </li>
            <li class="group">
              <a href="https://wa.me/0818397717/?text=Halo,%20saya%20tertarik%20dengan%20produk%20Mont%20Cartenz" class="flex text-base text-secondary py-2 mx-8 group-hover:text-primary">Contacts</a>
            </li>
            <li class="group">
              <router-link to="/Recipes" class="flex text-base text-secondary py-2 mx-8 group-hover:text-primary">Recipes</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>


</template>

<style scoped>

</style>