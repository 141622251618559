<script>
import axios from "axios";

export default {
  data() {
    return {
      recipes: [
        {
          name: "",
          productName: [],
          ingredients: "",
          instructions: "",
          photo: null,
        },
      ],
      products: [],
      searchProduct: "",
    };
  },
  mounted() {
    this.getProductName();
    document.title = 'Upload Recipes';
  },
  methods: {
    async getProductName() {
      const response = await axios.get("https://flask.montcartenz.com/GetProductName");
      this.products = response.data;
    },
    addRecipe() {
      this.recipes.push({
        name: "",
        productName: [],
        ingredients: "",
        instructions: "",
        photo: null,
      });
    },
    removeRecipes(index) {
      this.recipes.splice(index, 1);
    },
    async submitForm() {
      const formData = new FormData();

      console.log(this.recipes);

      this.recipes.forEach(recipe => {
        formData.append(`name[]`, recipe.name);
        formData.append(
          `productName[]`,
          recipe.productName.join(",")
        );
        formData.append(`ingredients[]`, recipe.ingredients);
        formData.append(`instructions[]`, recipe.instructions);
        formData.append(`photo[]`, recipe.photo);
      });

      axios.post('https://flask.montcartenz.com/UploadRecipes', formData)
          .then(response => {
            console.log(response.data);
            alert("Semua Resep Sudah di Upload");
            window.location.reload();
          })
          .catch(error => {
            console.error(error);
            // window.location.reload();
          })
    },
    onFileChange(event, index) {
      // Pastikan recipes[index] sudah didefinisikan sebelum mengakses propertinya
      this.recipes[index].photo = event.target.files[0];
    },
    filterProducts() {
      return this.products.filter((product) =>
        product.toLowerCase().includes(this.searchProduct.toLowerCase())
      );
    },
    toggleProduct(recipeIndex, product) {
      const selectedRecipe = this.recipes[recipeIndex];
      const index = selectedRecipe.productName.indexOf(product);
      if (index === -1) {
        selectedRecipe.productName.push(product);
      } else {
        selectedRecipe.productName.splice(index, 1);
      }
    },
  },
};
</script>

<template>
<div class="container mx-auto p-4 my-36">
  <h1 class="font-semibold text-2xl mb-4 text-compliment">Upload Recipes</h1>
  <form @submit.prevent="submitForm" enctype="multipart/form-data">
    <div v-for="(recipe, index) in recipes" :key="index" class="mb-6">
      <h2 class="text-lg font-semibold mb-2">Recipe {{index + 1}}</h2>
      <div class="mb-4">
        <label for="name" class="block text-sm font-medium text-compliment">Recipe Name</label>
        <input v-model="recipe.name" type="text" :id="'recipeName' + index" :name="'name[]'" required class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary">
      </div>
      <div class="mb-4">
        <label for="productName" class="block text-sm font-medium text-gray-700">
          Product Name
        </label>
        <!-- Input pencarian product -->
        <input
          v-model="searchProduct"
          type="text"
          placeholder="Search Product..."
          class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary"
        />
        <!-- Daftar checkbox product -->
        <div class="flex flex-wrap mt-2">
          <button
            v-for="(product, productIndex) in filterProducts()"
            :key="productIndex"
            type="button"
            name="productName[]"
            class="bg-gray-200 hover:bg-gray-300 text-secondary font-medium py-2 px-4 rounded-full mr-2 mb-2"
            @click="toggleProduct(index, product)"
            :class="{ 'bg-primary text-white': recipe.productName.includes(product) }"
          >
            {{ product }}
          </button>
        </div>
      </div>
      <div class="mb-4">
        <label for="ingredients" class="block text-sm font-medium text-gray-700">Ingredients</label>
        <textarea v-model="recipe.ingredients" id="ingredients" name="ingredients[]" required
                  class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary"></textarea>
      </div>
      <div class="mb-4">
        <label for="instructions" class="block text-sm font-medium text-gray-700">Instructions</label>
        <textarea v-model="recipe.instructions" id="instructions" name="instructions[]" required
                  class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary"></textarea>
      </div>
      <div class="mb-4">
        <label for="photo" class="block text-sm font-medium text-gray-700">Photo</label>
        <input @change="onFileChange($event, index)" type="file" id="photo" name="photo[]" accept="image/*" required
               class="bg-gray-100 mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-primary">
      </div>
      <button type="button" @click="removeRecipes(index)" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Remove</button>
    </div>
    <div class="mb-4">
      <button type="button" @click="addRecipe"
              class="px-4 py-2 bg-compliment text-white rounded hover:bg-secondary">Add Recipe</button>
    </div>
    <button type="submit" class="px-4 py-2 bg-primary text-secondary rounded hover:bg-yellow-200">Upload Recipes</button>
  </form>
</div>
</template>

<style>

</style>