<template>
  <div class="container mx-auto p-4 my-36">
    <div class="p-24">
      <h1 class="text-4xl font-bold mb-4 text-primary text-center">Welcome to Our Website</h1>
      <p class="text-lg font-semibold mb-8 text-primary text-center">
        Explore amazing content and features on our platform. Start your journey now!
      </p>
    </div>

    <div class="py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <!-- Card 1 -->
      <div class="bg-white p-6 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-2">Feature 1</h2>
        <p class="text-gray-600">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <!-- Card 2 -->
      <div class="bg-white p-6 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-2">Feature 2</h2>
        <p class="text-gray-600">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <!-- Card 3 -->
      <div class="bg-white p-6 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-2">Feature 3</h2>
        <p class="text-gray-600">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {},
  data(){
    return{

    };
  },
  mounted() {
    document.title = 'Home';
  }
}
</script>

<style>
/*.home-header{*/
/*  background-image: url("https://https://flask.montcartenz.com/static/image/Fruits.jpg");*/
/*  background-attachment: fixed;*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*}*/
</style>