<script>
  import ProductForm from "@/components/ProductForm.vue";

  export default {
    components:{
      ProductForm
    },
    mounted() {
      document.title = 'Upload Product';
    },
  };
</script>

<template>
  <div class="container mx-auto p-4 my-36">
    <ProductForm></ProductForm>
  </div>
</template>

<style scoped>

</style>