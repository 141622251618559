<template>
  <div class="container mx-auto p-4 md:px-8 my-8 pt-36 pb-32">
    <h1 class="text-4xl font-bold mb-8">Edit Recipes</h1>
    <div v-if="recipes.length === 0" class="text-gray-600 text-lg">
      No recipes available. Please add recipes.
    </div>
    <div v-else>
      <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <li v-for="recipe in recipes" :key="recipe.id" class="bg-white rounded-lg shadow-md overflow-hidden">
          <div class="p-4">
            <h2 class="text-xl font-semibold mb-2">{{ recipe.name }}</h2>
            <p class="text-gray-600 truncate">{{ recipe.bahan }}</p>
          </div>
          <div class="flex items-center justify-between p-4 bg-gray-100">
            <button @click="editRecipe(recipe)" class="text-blue-600 hover:underline">Edit</button>
            <button @click="deleteRecipe(recipe.id)" class="text-red-600 hover:underline">Delete</button>
          </div>
        </li>
      </ul>
    </div>

    <!-- Show the edit form only when isEditFormVisible is true -->
    <div v-if="isEditFormVisible" class="popup">
      <div class="popup-content">
        <h2 class="text-2xl font-semibold mb-4">Edit Recipe</h2>
        <form @submit.prevent="saveEditedRecipe" class="space-y-4">
          <div class="mb-4">
            <label for="recipeName" class="block text-sm font-medium text-gray-600">Recipe Name:</label>
            <input v-model="editedRecipe.name" type="text" id="recipeName" name="recipeName" class="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500">
          </div>
          <div class="mb-4">
            <label for="ingredients" class="block text-sm font-medium text-gray-600">Ingredients:</label>
            <textarea v-model="editedRecipe.bahan" id="ingredients" name="ingredients" rows="4" class="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"></textarea>
          </div>
          <div class="mb-4">
            <label for="instructions" class="block text-sm font-medium text-gray-600">Instructions:</label>
            <textarea v-model="editedRecipe.cara" id="instructions" name="instructions" rows="4" class="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"></textarea>
          </div>
          <div class="mb-4">
            <button type="submit" class="bg-primary text-white py-2 px-4 hover:text-primary rounded hover:bg-secondary">Save Changes</button>
            <button @click="cancelEdit" type="button" class="bg-gray-300 text-black py-2 px-4 rounded ml-2">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      recipes: [],
      editingRecipe: null,
      editedRecipe: {
        name: "",
        bahan: "",
        cara: "",
      },
      isEditFormVisible: false, // Tambah properti isEditFormVisible
    };
  },
  methods: {
    async getRecipes() {
      try {
        const response = await axios.get('https://flask.montcartenz.com/GetRecipes');
        this.recipes = response.data;
      } catch (e) {
        console.error("Error Fetching Recipes:", e);
      }
    },
    async deleteRecipe(recipeId) {
      try {
        await axios.delete(`https://flask.montcartenz.com/DeleteRecipe/${recipeId}`);
        this.recipes = this.recipes.filter((recipe) => recipe.id !== recipeId);
      } catch (e) {
        console.error('Error Deleting Recipe:', e);
      }
    },
    editRecipe(recipe) {
      this.editingRecipe = recipe;
      this.editedRecipe = {
        name: recipe.name,
        bahan: recipe.bahan,
        cara: recipe.cara,
      };
      this.isEditFormVisible = true; // Set isEditFormVisible menjadi true saat tombol Edit ditekan
    },
    async saveEditedRecipe() {
      try {
        console.log('Saving edited recipe:', this.editedRecipe);
        const response = await axios.put(`https://flask.montcartenz.com/EditRecipe/${this.editingRecipe.id}`, this.editedRecipe);
        console.log('Server response:', response);

        // Update the recipe list with the edited recipe
        const index = this.recipes.findIndex(recipe => recipe.id === this.editingRecipe.id);
        if (index !== -1) {
          this.recipes[index].name = this.editedRecipe.name;
          this.recipes[index].bahan = this.editedRecipe.bahan;
          this.recipes[index].cara = this.editedRecipe.cara;
        }

        this.cancelEdit(); // Close the edit form after saving changes
      } catch (e) {
        console.error('Error Saving Edited Recipe:', e);
      }
    },
    cancelEdit() {
      this.isEditFormVisible = false; // Hide the edit form
      this.editingRecipe = null;
      this.editedRecipe = {
        name: "",
        bahan: "",
        cara: "",
      };
    },
  },
  mounted() {
    this.getRecipes();
    document.title = 'Edit Recipe';
  },
}
</script>

<style scoped>
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
}

.popup-content button {
  margin-top: 10px;
}
</style>
